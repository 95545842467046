<template>
  <div class="flex justify-center pt-20">
    <div class="card">
      <div
        style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;"
      >
        <i class="checkmark">✓</i>
      </div>
      <h1>Success</h1>
      <p>
        We received your purchase request;<br />
        we'll be in touch shortly!
      </p>
      <div style="text-align:center">
        <router-link to="/" class="btn btn-primary mt-3"
          >Return Home</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  color: #0c4369;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
  text-align: center;
}
p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
  text-align: center;
}
i {
  color: #0c4369;
  font-size: 100px;
  line-height: 200px;
  margin-left: 45px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
